import React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { connect } from "react-redux"
import moment from "moment"
import MarkdownView from "react-showdown"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  PostHeader,
  PostContainer,
  PostTags,
  PostTag,
  Tag,
} from "../components/styled/post.js"
import { Container } from "../components/styled/container"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const Link = styled(GatsbyLink)`
  text-decoration: none;
  margin: 0;
`

const GameCard = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  text-align: center;
  background-color: ${props => props.theme.colors.gameCardBg};
  margin-bottom: 16px;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
`

const GameImage = styled(Img)`
  height: 100% !important;
  @media (max-width: 576px) {
    height: 200px !important;
    width: 100% !important;
  }
`

const GameCardContent = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  grid-gap: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  grid-auto-flow: row;

  @media (max-width: 576px) {
    text-align: center;
    margin-top: -7px;
    padding-top: 0;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }
`

const GameCardTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.gameCardTitle};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
`

const GameCardData = styled.p`
  color: ${props => props.color};
  margin-bottom: 0;
`

const LowPrice = ({ price, currency }) => {
  return currency
    ? new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(
        Math.min.apply(
          Math,
          price.map(price => price.price)
        )
      )
    : new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(
        Math.min.apply(
          Math,
          price.map(price => price.price)
        )
      )
}

const NewsTemplate = ({ data, currency }) => {
  const getCurrency = prices => {
    let dataEUR = prices.filter(price => price.currency === "EUR")
    let dataUSD = prices.filter(price => price.currency === "USD")

    return currency ? dataEUR : dataUSD
  }
  return (
    <Layout>
      <AdLayout>
        <AdBanner slot="6517054732" />
        <SEO
          title={`${data.strapiPostNew.title} - FGCharts`}
          description={data.strapiPostNew.description}
        />
        <Container style={{ flex: "10 0 auto" }}>
          <div>
            <PostHeader>
              <h2 style={{ marginBottom: 16 }}>{data.strapiPostNew.title}</h2>
              <p style={{ marginBottom: 0 }}>
                {`${data.strapiPostNew.author} - ${moment(
                  data.strapiPostNew.createdAt
                ).format("LL")}`}
              </p>
              <PostTags>
                {data.strapiPostNew.tags.map((tag, index) => (
                  <PostTag
                    key={index}
                    right={data.strapiPostNew.tags.length > 1}
                  >
                    <Tag>{tag}</Tag>
                  </PostTag>
                ))}
              </PostTags>
            </PostHeader>
            <PostContainer>
              <Link to={`/games/${data.strapiPostNew.game.slug}`}>
                <GameCard>
                  <GameImage
                    fixed={data.strapiPostNew.game.banner.childImageSharp.fixed}
                  />
                  <GameCardContent>
                    <div>
                      <GameCardTitle>Current Players</GameCardTitle>
                      <GameCardData color="#F19954">
                        {data.strapiPostNew.game.currentPlayersCount}
                      </GameCardData>
                    </div>
                    <div>
                      <GameCardTitle>Current Lowest Price </GameCardTitle>
                      <GameCardData color="#8FED6E">
                        {data.strapiPostNew.game.affiliates.length === 0 ? (
                          "N/A"
                        ) : (
                          <LowPrice
                            price={getCurrency(
                              data.strapiPostNew.game.affiliates
                            )}
                            currency={currency}
                          />
                        )}
                      </GameCardData>
                    </div>
                  </GameCardContent>
                </GameCard>
              </Link>
              <MarkdownView
                markdown={data.strapiPostNew.content}
                options={{ table: true, emoji: true }}
              />
            </PostContainer>
          </div>
        </Container>
        <AdBanner slot="6517054732" />
      </AdLayout>
    </Layout>
  )
}

export const query = graphql`
  query NewsTemplate($slug: String!) {
    strapiPostNew(slug: { eq: $slug }) {
      title
      author
      createdAt
      content
      tags
      description
      game {
        slug
        currentPlayersCount
        affiliates {
          price
          currency
        }
        banner {
          childImageSharp {
            fixed(width: 168, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
const mapStateToProps = state => ({
  currency: state.currency,
})

export default connect(mapStateToProps, null)(NewsTemplate)
